@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-zinc-100 ;
}

button {
  @apply px-3 py-2 rounded bg-blue-500 text-white
}

.hearts-container {
  margin-top: -25px; /* Adjust this value as needed to move the hearts higher */
}
